import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { StepService } from '../services/step.service';
import { LanaService } from '../services/lana.service';

import { ExerciseComponent } from '../exercise/exercise.component'
import { Router } from '@angular/router';

import { Step } from "../models/Step";

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css']
})
export class StepComponent implements OnInit {
  @Input() step: any;
  @Input() exercise: any;
  @Output() updateExercise = new EventEmitter<boolean>();
  @Output() updateExerciseSteps = new EventEmitter<boolean>();
  model: string;

  constructor(public stepService: StepService, public lanaService: LanaService) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
  }

  ngAfterContentInit() {
  }

  ngOnChanges() {
  }


  public onModel(myModel: string) {
    this.model = myModel;
  }

  update() {
    this.exercise.getSteps(this.exercise.exerciseId);
    this.exercise.activeIndex = this.exercise.activeIndex - 1;
    if (this.exercise.activeIndex < 0) {
      this.exercise.activeIndex = 0;
    }
    this.exercise.ngOnInit();
  }

  onModelChanged($event) {
    this.saveMatrixTransforms(this.step.initialModels);
    this.saveMatrixTransforms(this.step.correctModels);
    this.stepService.editStep(this.step);
    //update next steps
    this.stepService.getNumberSteps(this.step.exerciseId).subscribe(
      nsteps => {
        let count = nsteps.count;
        if (this.step.order < count - 1) {
          this.stepService.updateNextSteps(this.step.exerciseId, this.step.order, this.step.initialModels, this.step.correctModels, this.emitUpdateExerciseSteps, this);
        }
        else {
          this.updateExerciseSteps.emit(true);
        }
      });
  }

  emitUpdateExerciseSteps(self) {
    self.updateExerciseSteps.emit(true);
  }

  onStepDeleted($event) {
    //console.log("emit updateExercise");
    this.updateExercise.emit(true);
  }

  saveMatrixTransforms(models) {
    models.forEach(element => {
      element.matrixWorld = this.lanaService.getMatrixWorld(element.name);
      //console.log("saveMatrixTransforms", element);
    });

  }

}
