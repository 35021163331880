import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";

import { Teacher } from "../models/Teacher";
import { Exercise } from "../models/Exercise";

import { AuthService } from '../services/auth.service';
import { ExerciseService } from '../services/exercise.service';
import { SpinnerService } from "../services/spinner.service";

import { SelectItem } from 'primeng/primeng';
import { TranslateService } from "@ngx-translate/core";

//import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-exercise-list',
  templateUrl: './exercise-list.component.html',
  styleUrls: ['./exercise-list.component.css']
  //providers: [MessageService]
})
export class ExerciseListComponent implements OnInit {
  names: string[];
  //idMoodle: string[];
  teacher: Teacher;
  myExercises: any;
  key: string;
  name: string;
  //myExercise: Exercise;

  sub: any;
  displayNewExerciseDialog: boolean = false;
  //exerciseform: FormGroup;
  exercise: Exercise = new Exercise();

  selectedOwner: string[] = ['Mine'];
  public: boolean = false;
  author: boolean = false;

  exerciseform: FormGroup;


  sortOptions: SelectItem[];
  sortKey: string;
  sortField: string;
  sortOrder: number;

  constructor(private authService: AuthService,
    private exerciseService: ExerciseService,
    private spinnerService: SpinnerService,
    private fb: FormBuilder,
    private translate: TranslateService,
    /*private messageService: MessageService*/) {
    this.names = [];
  }

  paginate(event) { }

  ngOnInit() {
    /*this.sub = this.route.params.subscribe(params => {
      this.idMoodle = params['id']; // (+) converts string 'id' to a number

      // In a real app: dispatch action to load the details here.
    });*/
    this.exerciseform = this.fb.group({
      'title': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
      'keyword': new FormControl([], Validators.required),
      'isPublic': new FormControl(false),
    });

    this.authService.getLogged().subscribe(
      teacher => {
        this.teacher = teacher;
        this.loadProjects();
      }
    );

    this.sortOptions = [{
      label: this.translate.instant('ModifiedDateNewFirst'), value: 'newest'
    }, { label: this.translate.instant('ModifiedDateOldFirst'), value: 'oldest' },
    { label: this.translate.instant('Title'), value: 'title' },
    { label: this.translate.instant('Public'), value: 'public' },
    { label: this.translate.instant('Private'), value: 'private' }/*,
    { label: this.translate.instant('Author'), value: 'author' }*/
    ];

  }

  showNewExerciseDialog() {
    this.exerciseform = this.fb.group({
      'title': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
      'keyword': new FormControl([], Validators.required),
      'isPublic': new FormControl(false),
    });
    this.displayNewExerciseDialog = true;
  }

  onSortChange(event) {
    let value = event.value;
    //this.mySteps.sort(function (a, b) { return (a.order - b.order) });
    if (this.myExercises.length > 0) {
      if (value === "public") {
        this.myExercises.sort(function (a, b) { return (b.isPublic - a.isPublic) });
      }
      else if (value === "private") {
        this.myExercises.sort(function (a, b) {
          return (a.isPublic - b.isPublic)
        });
      }
      else if (value === "title") {
        this.myExercises.sort(function (a, b) {
          let titleA = a.title.toLowerCase();
          let titleB = b.title.toLowerCase();
          if (titleA > titleB) return 1;
          else return -1;
        });
      }
      else if (value === "newest") {
        this.myExercises.sort(function (a, b) {
          let dateA = a.modified
          let dateB = b.modified;
          if (dateA < dateB) return 1;
          else return -1;
        });
        //this.myExercises.sort(function (a, b) { return (a.modified.toLowerCase() - b.modified.toLowerCase()) });
      }
      else if (value === "oldest") {
        this.myExercises.sort(function (a, b) {
          let dateA = a.modified
          let dateB = b.modified;
          if (dateA > dateB) return 1;
          else return -1;
        });
      }




    }
    //this.ngOnInit();

    /*if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }*/
  }

  onSubmit() {
    let value = this.exerciseform.value;
    this.newExercise(value);
  }

  cancel() {
    this.displayNewExerciseDialog = false;
  }



  newExercise(value: any) {
    this.spinnerService.displayLoader(true);
    let data: any = [];
    data.title = value.title;
    data.description = value.description;
    data.keyword = value.keyword;
    data.isPublic = value.isPublic;
    this.exerciseService.newExercise(data, this.teacher.id, this.refreshList, this);
    this.displayNewExerciseDialog = false;
    /*this.loadProjects();
    this.ngOnInit();*/
    //window.location.reload();
  }

  refreshList(self) {
    self.loadProjects();
    //self.ngOnInit();
  }

  onExerciseChanged($event) {
    this.loadProjects();
  }

  findByKeywordName(key: string, name: string) {
    this.myExercises = [];
    if (key && !name) {
      this.exerciseService.findByKeyword(key).subscribe(
        exercises => {
          this.myExercises = exercises;
        });
    }
    else if (!key && name) {
      this.exerciseService.findByName(name).subscribe(
        exercises => {
          this.myExercises = exercises;
        });
    }
    else {
      this.exerciseService.findByKeywordName(key, name).subscribe(
        exercises => {
          this.myExercises = exercises;
        });
    }
  }


  loadProjects() {
    //search projects where teacher.id is editor
    this.myExercises = [];
    if (this.teacher) {
      this.exerciseService.getAllFromAuthor(this.teacher.id).subscribe(
        exercises => {
          this.myExercises = exercises;
        });
    }
  }

  searchPublic() {
    this.myExercises = [];
    //search all public exercises
    this.exerciseService.getAllPublicExercises().subscribe(
      exercises => {
        this.myExercises = exercises;
      });
    //}
  }


}
