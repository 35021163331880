import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { interval } from 'rxjs';

import { User, emptyUser } from '../models/User';
import { Teacher, emptyTeacher } from '../models/Teacher';
import { environment } from '../../environments/environment';
import { StoreService } from './store.service';

@Injectable()
export class AuthService {
  private TOKEN_NAME: string = environment.tokenName;
  private BASE_URL: string = environment.apiEndpoint + '/api/teachers/';
  private user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private user$: Observable<User> = this.user.asObservable();
  private teacher: BehaviorSubject<Teacher> = new BehaviorSubject<Teacher>(null);
  private teacher$: Observable<Teacher> = this.teacher.asObservable();
  private tokenHasBeenChecked = false;
  private RESET_TOKEN_CHANGED = 600000;

  constructor(private http: Http, private storeService: StoreService) {
    // force check of token is valid each RESET_TOKEN_CHANGED miliseconds
    interval(this.RESET_TOKEN_CHANGED).subscribe(x => {
      this.tokenHasBeenChecked = false;
    });
  }

  login(idMoodle: string, fullName: string, username: string): Observable<Teacher> {
    const loginService = 'loginB';
    //console.log("auth service");
    //console.log("service: ", this.BASE_URL + loginService);
    return this.http
      .post(
        this.BASE_URL + loginService,
        { idMoodle: idMoodle }
      ).pipe(
        map((response: Response) => {
          // login successful if there's a accessToken token in the response
          let teacher = response.json();
          //console.log("teacher:");
          //console.log(teacher);

          let loggedUser: Teacher = null;
          if (teacher && teacher.accessToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(
              this.TOKEN_NAME,
              JSON.stringify(teacher.accessToken)
            );
            loggedUser = new Teacher();
            loggedUser = teacher.accessToken;
          }
          this.teacher.next(loggedUser);
          this.storeService.init();
          loggedUser.fullName = fullName;
          loggedUser.username = username;
          return loggedUser;
          //return teacher;
        })
      );
  }

  editTeacher(id: string, idMoodle: string, name: string, username: string) {
    //const newService = 'newExercise';
    //console.log("Resource editExercise: ", resource);
    //let modified = new Date();
    return this.http
      .put(
        this.BASE_URL,
        {
          id: id,
          idMoodle: idMoodle,
          fullName: name,
          usermane: username,
        }
      ).toPromise()
      .then(res => {
        //console.log("res: ", res.json());
        this.teacher = res.json();
        <any[]>res.json().data
      })
      .then(data => {
        //console.log("return data editTeacher: ", data);
        return data;
      });
  }

  getLogged(): Observable<Teacher> {
    //console.log('getlogged');
    return this.teacher$;
  }

  logOut(): Observable<boolean> {
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    this.removeToken();
    this.storeService.reset();
    this.user.next(null);
    return this.http
      .post(this.BASE_URL + 'logout?access_token=' + accessToken.id, {
        withCredentials: true
      })
      .pipe(
        map((response: Response) => {
          return true;
        })
      );
  }

  getUser(): User {
    let loggedUser: User = null;
    if (localStorage.getItem(this.TOKEN_NAME)) {
      loggedUser = new User();
      loggedUser = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
      this.user.next(loggedUser);
    }
    return loggedUser;
  }

  setUser(user: User) {
    this.user.next(user);
  }

  updateUserCompanyId(companyId: string): void {
    const loggedUser = this.user.getValue();
    loggedUser.companyId = companyId;
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    accessToken.companyId = companyId;
    localStorage.setItem(this.TOKEN_NAME, JSON.stringify(accessToken));
    this.user.next(loggedUser);
  }

  isLoggedIn(): Observable<boolean> {
    // If no user on local storage, user is not logged in
    const loggedUser = this.getUser();
    if (loggedUser) {
      // get user data from backend to check accesstoken is valid
      const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
      const userId = accessToken ? accessToken.userId : '';
      const token = accessToken ? accessToken.id : '';
      if (this.tokenHasBeenChecked) {
        return of(true);
      } else {
        return this.http
          .get(this.BASE_URL + userId + '?access_token=' + token, {
            withCredentials: true
          })
          .pipe(
            map((response: Response) => {
              this.tokenHasBeenChecked = true;
              this.user.next(loggedUser);
              return true;
            }),
            catchError(error => {
              console.log('Error in loggin in', error);
              this.removeToken();
              this.user.next(null);
              return of(false);
            })
          );
      }
    } else {
      this.user.next(null);
      return of(false);
    }
  }

  removeToken(): void {
    localStorage.removeItem(this.TOKEN_NAME);
    this.tokenHasBeenChecked = false;
  }

  changeLanguageToken(newLang: string): void {
    const loggedUser = this.user.getValue();
    if (loggedUser) {
      loggedUser.language = newLang;
      const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
      accessToken.language = newLang;
      localStorage.setItem(this.TOKEN_NAME, JSON.stringify(accessToken));
      this.user.next(loggedUser);
    }
  }
}

export const AUTH_PROVIDERS: Array<any> = [
  { provide: AuthService, useClass: AuthService }
];
