import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
// import { Category } from '../models/Category';
import { RestService } from './rest.service';
import { map, tap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { Teacher, emptyTeacher } from '../models/Teacher';
import { Promise } from 'q';

declare var LANA3D: any;
declare var VIEWER: any;

@Injectable()
export class LanaService {
  editor: any;
  scene: any;

  private myModel: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private myModel$: Observable<string> = this.myModel.asObservable();
  private clearScene: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private clearScene$: Observable<boolean> = this.clearScene.asObservable();

  private myScene: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private myScene$: Observable<any> = this.myScene.asObservable();

  private myEditor: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private myEditor$: Observable<any> = this.myScene.asObservable();


  constructor() {
    this.editor = VIEWER.Editor.create();
    this.scene = LANA3D.NodeManager.createNode("scene");
    this.editor.setScene(this.scene);

  }

  /*addModel(model: string) {
    console.log("lanaService:", model);
    this.myModel.next(model);

  }*/

  addModel(model: string, matrix, callback, params) {
    let files = [];
    files.push(model);
    this.editor.loadSceneFromFile(files, matrix, callback, params);
    //callback();
    //this.myEditor.next(this.editor);
    //return true;
  }

  deleteModel(model: string) {
    /*let scene = this.editor.getScene();
    //let sceneNode = scene.getSceneNode();
    let nodo = scene.findChildrenByName(model, true);
    this.editor.deleteNode(nodo);*/
    let name = model.substring(model.lastIndexOf("/") + 1);
    //console.log("name: ", name);
    let scene = this.editor.getScene();
    //console.log("scene: ", scene);
    //let sceneNode = scene.getSceneNode();
    let nodo = scene.findChildrenByName(name, true);
    //console.log("nodo:", nodo);
    if (nodo.length > 0) {
      this.editor.deleteNode(nodo[0]);
    }
  }

  getModel() {
    return this.myModel.asObservable();
  }

  resetScene(callback, self) {
    this.editor.deleteScene(callback, self);
    this.myEditor.next(this.editor);
    //console.log("resetScene this.editor: ", this.editor);
  }

  getResetScene() {
    return this.clearScene.asObservable();
  }

  getScene() {
    return this.myScene.asObservable();
  }

  getEditor() {
    return this.myEditor.asObservable();
  }

  getMatrixWorld(elementName) {
    elementName = elementName.replace(".viwork", ".dae");
    return this.editor.getMatrixWorld(elementName);
  }

  setMatrixWorld(elementName, matrix) {
    elementName = elementName.replace(".viwork", ".dae");
    this.editor.setMatrixWorld(elementName, matrix);
  }


  /*sendMessage(message: Message): void {
    this.subject.next(message);
  }

  getMessage(): Observable<Message> {
    return this.subject.asObservable();
  }*/
}
