import { Component, OnInit, Input } from '@angular/core';

import { Step } from "../models/Step";
import { Teacher } from "../models/Teacher";
import { Exercise } from "../models/Exercise";
import { MenuItem } from 'primeng/api';
import { AuthService } from '../services/auth.service';
import { StepService } from '../services/step.service';
import { ExerciseService } from '../services/exercise.service';
import { LanaService } from '../services/lana.service';
import { MessageService } from 'primeng/api';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";



//import { Message } from 'primeng/api';


@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.css']
})
export class ExerciseComponent implements OnInit {

  //@Input() exercise: Exercise;

  exercise: Exercise;
  teacher: Teacher;
  nSteps: any;
  mySteps: any;
  exerciseId: string;
  myStep: any;
  stepsMenu: MenuItem[];
  title: string = "";

  activeIndex: number = 0;
  step: Step = new Step();
  changeStep: boolean = false;
  newStepPosition: any;

  showHelp: boolean = false;
  currentTutorialPage: number;// = 2;
  tutorialNumPages: number = 0;
  pdfSrc: string = '../../assets/tutorial/tutorial.pdf';


  //msgs: Message[] = [];

  constructor(private authService: AuthService,
    private stepService: StepService,
    private exerciseService: ExerciseService,
    private router: Router,
    private lanaService: LanaService,
    private route: ActivatedRoute, private messageService: MessageService,
    private translate: TranslateService) {

    this.route.params.subscribe(params => {
      this.exerciseId = params['exerciseId'];
      //var name = params['name']

      let newStep: any = [];
      newStep.title = "Insert title";
      newStep.description = "Insert description";
      newStep.help = "Insert help";
      newStep.initialModels = [];
      newStep.correctModels = [];
      newStep.incorrectModels = [];
      newStep.order = 0;
      newStep.exerciseId = "this.exerciseId";
      this.myStep = newStep;
    })

    this.currentTutorialPage = 1;

  }

  ngOnInit() {
    //ToDo: read the steps of the exercise
    //this.nSteps = 0;
    //this.lanaService.resetScene();
    //this.myStep = undefined;
    this.authService.getLogged().subscribe(
      teacher => {
        this.teacher = teacher;
      }
    );
    this.exerciseService.getByExerciseId(this.exerciseId).subscribe(
      exercise => {
        this.exercise = exercise;
        this.title = exercise[0].title;
        this.getSteps(this.exerciseId);
        //this.myStep = this.mySteps[0];
      }
    );

  }

  ngOnDestroy() {
    this.myStep = undefined;
  }


  getSteps(exerciseId) {
    //search projects where teacher.id is editor
    this.mySteps = [];
    let myOptions: any[] = [];
    this.stepService.getAllFromExercise(exerciseId).subscribe(
      steps => {
        this.mySteps = steps;
        this.mySteps.sort(function (a, b) { return (a.order - b.order) });
        //console.log(this.mySteps);
        this.nSteps = this.mySteps.length;

        if (this.nSteps === 0) {
          let newStep: any = [];
          newStep.title = "Insert title";
          newStep.description = "Insert description";
          newStep.help = "Insert help";
          newStep.initialModels = [];
          newStep.correctModels = [];
          newStep.incorrectModels = [];
          newStep.helpFiles = [];
          newStep.order = 0;
          newStep.exerciseId = this.exerciseId;


          this.stepService.newStep(newStep).subscribe(step => {
            this.mySteps.push(step);
            this.ngOnInit();

          })
        }

        for (var i = 0; i < this.mySteps.length; i++) {
          myOptions[i] = {
            command: (event: any) => {
              this.activeIndex = event.index;
              let stepId = this.mySteps[event.index].id;
              this.stepService.getStepById(stepId).subscribe(step => {
                this.myStep = step[0];
              });
              //this.myStep = this.mySteps[event.index];
              //this.ngOnInit();
            }
          };
        }


        this.stepsMenu = myOptions;
        this.myStep = this.mySteps[this.activeIndex];

      });
  }

  showCreateStepPositionOption() {
    this.newStepPosition = this.nSteps + 1;
    this.changeStep = true;
  }

  showHelpDialog() {
    this.showHelp = true;
  }

  cancelNewStep() {
    this.changeStep = false;
  }

  updateExercise($event) {
    this.getSteps(this.exerciseId);
    this.activeIndex = this.activeIndex - 1;
    if (this.activeIndex < 0) {
      this.activeIndex = 0;
    }
    this.ngOnInit();
  }

  updateExerciseSteps($event) {
    //this.getSteps(this.exerciseId);

    this.stepService.getAllFromExercise(this.exerciseId).subscribe(
      steps => {
        this.mySteps = steps;
        this.mySteps.sort(function (a, b) { return (a.order - b.order) });
      });
    /*this.activeIndex = this.activeIndex - 1;
    if (this.activeIndex < 0) {
      this.activeIndex = 0;
    }
    this.ngOnInit();*/
  }



  createStep() {
    //hide modal
    if (isNaN(this.newStepPosition)) {
      let possibleStepPosition = this.nSteps + 1;
      let summary = "Error";
      let detail = "The step position has to be a number between 1 and " + possibleStepPosition;
      this.messageService.add({ severity: 'error', summary: summary, detail: detail });
      this.newStepPosition = this.nSteps + 1;
    }
    else if (this.newStepPosition < 1 || this.newStepPosition > (this.nSteps + 1)) {
      //this.changeStep = false;
      let possibleStepPosition = this.nSteps + 1;
      let summary = "Error";
      let detail = "The step position has to be between 1 and " + possibleStepPosition;
      this.messageService.add({ severity: 'error', summary: summary, detail: detail });
      this.newStepPosition = this.nSteps + 1;
    }
    else {
      this.changeStep = false;
      this.nSteps = this.mySteps.length;
      //console.log("CREATE STEP: ", this.mySteps);

      if (this.newStepPosition === (this.nSteps + 1)) {
        //last position
        let newStep: any = [];
        newStep.title = "Insert title";
        newStep.description = "Insert description";
        newStep.help = "Insert help";
        //get last last step models
        //actualize lastStep in case it has been modified by previous
        let stepId = this.mySteps[this.nSteps - 1].id;
        this.stepService.getStepById(stepId).subscribe(step => {
          let lastStep = step[0];
          let previousModels: any[] = [];
          for (let model of lastStep.initialModels) {
            previousModels.push(model);
          }
          for (let model of lastStep.correctModels) {
            previousModels.push(model);
          }
          newStep.initialModels = previousModels;
          newStep.correctModels = [];
          newStep.incorrectModels = [];
          newStep.exerciseId = this.exerciseId;

          newStep.order = this.nSteps;

          this.stepService.newStep(newStep).subscribe(step => {
            this.mySteps.push(step);
            this.activeIndex = this.mySteps.length - 1;
            this.ngOnInit();
          });
        });
      }
      else {
        //intermediate position
        let newStep: any = [];
        newStep.title = "Insert title";
        newStep.description = "Insert description";
        newStep.help = "Insert help";
        //get last last step models
        let stepId = this.mySteps[this.newStepPosition - 1].id;
        this.stepService.getStepById(stepId).subscribe(step => {
          let lastStep = step[0];
          let previousModels: any[] = [];
          if (this.newStepPosition - 1 > 0) {
            for (let model of lastStep.initialModels) {
              previousModels.push(model);
            }
          }

          /*for (let model of lastStep.correctModels) {
            previousModels.push(model);
          }*/
          newStep.initialModels = previousModels;
          newStep.correctModels = [];
          newStep.incorrectModels = [];
          newStep.exerciseId = this.exerciseId;

          newStep.order = this.newStepPosition - 1;

          //change the order of the following steps
          this.stepService.renumberStepsAdd(this.exerciseId, this.newStepPosition - 1, this.createNewStep, this, newStep);
        });
      }
    }
  }

  createNewStep(self, newStep) {
    self.stepService.newStep(newStep).subscribe(step => {
      self.mySteps.push(step);
      self.activeIndex = self.newStepPosition - 1;
      self.myStep = step;
      self.ngOnInit();
    });
  }

  prevTutorialPage() {
    this.currentTutorialPage = this.currentTutorialPage - 1;
  }

  nextTutorialPage() {
    this.currentTutorialPage = this.currentTutorialPage + 1;
  }

  checkFirsTutorialPage() {
    if (this.currentTutorialPage === 1) {
      return true;
    }
  }

  checkLastTutorialPage() {
    if (this.currentTutorialPage === this.tutorialNumPages) {
      return true;
    }
  }

  stateRender(event) {
    let info = event._pdfInfo;
    this.tutorialNumPages = info.numPages;
  }

}
