import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ISubscription } from "rxjs/Subscription";
import { StepComponent } from '../step/step.component';
import { Observable } from 'rxjs';
import $ from "jquery";
import { MessageService } from 'primeng/api';

import { LanaService } from '../services/lana.service';

import { TranslateService } from "@ngx-translate/core";

declare var LANA3D: any;
declare var VIEWER: any;

@Component({
  selector: 'app-lana',
  templateUrl: './lana.component.html',
  styleUrls: ['./lana.component.css'],
  encapsulation: ViewEncapsulation.Native
})
export class LanaComponent implements OnInit {

  @Output() modelChanged = new EventEmitter<boolean>();

  editor: any;
  scene: any;
  mesh: any;
  //@Input() stepComponent: StepComponent;

  myModel$: Observable<string>;
  model: string = "";

  visor: any;

  moveChecked: boolean = true;
  rotateChecked: boolean = false;
  selectedObject: boolean = false;
  movedObject: boolean = false;
  myObject: any;
  prevX: any = 0;
  prevY: any = 0;

  private subscription: ISubscription;


  @ViewChild('lanaContainer') lanaContainer: ElementRef;

  constructor(private lanaService: LanaService, private messageService: MessageService, private translate: TranslateService) { }

  ngOnInit() {

    //this.clearScene();
    this.subscription = this.lanaService.getEditor().subscribe(editor => {
      if (editor) {
        this.editor = editor;
        this.lanaContainer.nativeElement.appendChild(this.editor.getDomElement());
        this.editor.resize();
      }
    });


    /*this.editor = VIEWER.Editor.create();
    this.lanaContainer.nativeElement.appendChild(this.editor.getDomElement());
    this.editor.resize();*/
    /*
    this.scene = LANA3D.NodeManager.createNode("scene");
    this.mesh = LANA3D.NodeManager.createNode("mesh");*/

    //this.editor.setScene(this.scene);

    /*this.editor = VIEWER.Editor.create();
    this.lanaContainer.nativeElement.appendChild(this.editor.getDomElement());
    this.editor.resize();
    this.scene = LANA3D.NodeManager.createNode("scene");
    this.editor.setScene(this.scene);
    LANA3D.AssetManager.loadBundle("models/test2.dae", result => {
      let o = result.scene;
      o.getLocalTransform().getPosition().add(new LANA3D.Vector3(20, 0, 0));
      this.scene.addChild(o);
    });*/

    /*this.lanaService.getModel().subscribe(model => {
      console.log("getModel: ", model);
      if (model) {
        LANA3D.AssetManager.loadBundle(model, result => {
          let o = result.scene;
          o.getLocalTransform().getPosition().add(new LANA3D.Vector3(20, 0, 0));
          this.scene.addChild(o);
        });
      }
    });*/

    /*this.lanaService.getScene().subscribe(scene => {
      if (scene) {
        console.log("setSecene");
        this.scene = scene;
        this.editor.setScene(this.scene);
      }

    });*/



    /*this.lanaService.getResetScene().subscribe(reset => {
      console.log("resetScene: ", reset);
      if (reset) {
        this.clearScene();
      }
    });*/



    //console.log("myModel:", this.stepComponent.model);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    $("#viewport").dblclick(function (event) {
      //console.log("Evento doble click angular");
      event.preventDefault();
    });

    //console.log("this.lanaContainer.nativeElement", this.lanaContainer.nativeElement);
    //window.dispatchEvent(new Event(this.lanaContainer.nativeElement.));
  }

  clickLana(event) {
    let object = this.editor.getObjectToMove(event.clientX, event.clientY);
    if (object !== undefined) {
      this.myObject = object;
      //console.log("MUESTRO OPCIONES");
      $("#op").show(event);
      //let summary = "Objeto";
      //let detail = "Se ha encontrado objeto";
      //this.messageService.add({ severity: 'error', summary: summary, detail: detail });
      this.selectedObject = true;

    }
    else {
      this.selectedObject = false;
      this.movedObject = false;
    }
  }

  unclickLana(event) {
    if (this.movedObject) {
      this.modelChanged.emit(true);
    }

  }


  onMouseMove(event) {
    if (this.selectedObject) {
      this.movedObject = true;
    }
    /*if (this.selectedObject) {
      console.log("mouse mouse: ", this.myObject);
      if (event.clientX > this.prevX) {
        this.myObject.object.getPosition.add(new LANA3D.Vector3(0.1, 0, 0));
      }
      else if (event.clientX < this.prevX) {
        this.myObject.object.getPosition.add(new LANA3D.Vector3(-0.1, 0, 0));
      }
      if (event.clientY > this.prevY) {
        this.myObject.object.getPosition.add(new LANA3D.Vector3(0, 0, 0.1));
      }
      else if (event.clientY > this.prevY) {
        this.myObject.object.getPosition.add(new LANA3D.Vector3(0, 0, -0.1));
      }

      this.prevX = event.clientX;
      this.prevY = event.clientY;

    }*/


  }

  handleChangeMove(e) {
    this.moveChecked = e.checked;
    //console.log(this.moveChecked);
    if (this.moveChecked) {
      this.rotateChecked = false;
      this.editor.setControlMode('translate');
    }
    else {
      this.rotateChecked = true;
      this.editor.setControlMode('rotate');
    }
  }

  handleChangeRotate(e) {
    this.rotateChecked = e.checked;
    //console.log(this.rotateChecked);
    if (this.rotateChecked) {
      this.moveChecked = false;
      this.editor.setControlMode('rotate');
    }
    else {
      this.moveChecked = true;
      this.editor.setControlMode('translate');
    }
  }

}
