// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //apiEndpoint: 'https://viwork-app-server.herokuapp.com',
  //apiEndpoint: 'http://localhost:3000',
  apiEndpoint: 'http://ec2-18-219-132-59.us-east-2.compute.amazonaws.com:3000',
  //apiEndpoint: 'http://192.168.100.101:3000',
  tokenName: 'viworkToken'
};
